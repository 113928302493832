import ax from '@/store/utils/axiosConfig'
import Auth from '../utils/Auth'
import router from '../../router'

export default {
  async login({ commit }, { payload: { email, password }, $toast }) {
    try {
      commit('setLoadingIndicator', true, { root: true })
      const res = await ax.post('/auth/login/', {
        email,
        password,
      })

      Auth.accessToken = res.data.access_token
      Auth.refreshToken = res.data.refresh_token
      Auth.currentUser = res.data.user

      commit('logUserIn', { user: res.data.user, authenticated: true })
      commit('setLoadingIndicator', false, { root: true })
      router.push('/')
    } catch (e) {
      console.error('Invalid login credentials', e)
      commit('setLoadingIndicator', false, { root: true })

      $toast.add({
        severity: 'error',
        group: 'tr',
        summary: 'Invalid Credentials',
        detail: 'Please try again.',
        life: 5000,
      })
    }
  },
  async register({ commit }, { payload, $toast }) {
    try {
      await ax.post('/users/', {
        ...payload,
      })
      $toast.add({
        severity: 'success',
        group: 'tr',
        summary: 'Registration Sucessful',
        detail: 'The user has been sent a notification email.',
        life: 5000,
      })
      router.go()
    } catch (e) {
      console.error('Invalid login credentials', e)

      $toast.add({
        severity: 'error',
        group: 'tr',
        summary: 'Duplicate E-mail',
        detail: 'Email must be unique. Have you already registered this user?',
        life: 5000,
      })
    }
  },
  async upload_contact({ commit }, { createContactPayload }) {
    try {
      await ax.post('/assessments/upload_contact/', {
        ...createContactPayload,
      })
    } catch (e) {
      console.error('Server', e)
    }
  },
  async createNewUser({ commit }, { payload, $toast }) {
    try {
      await ax.post('/users/create/', {
        ...payload,
      })
      $toast.add({
        severity: 'success',
        group: 'tr',
        summary: 'Account Created',
        detail: 'You have successfully created your account and can now login.',
        life: 5000,
      })
      router.push('/login')
    } catch (e) {
      console.error('Invalid login credentials', e)

      $toast.add({
        severity: 'error',
        group: 'tr',
        summary: 'Duplicate E-mail',
        detail: 'Email must be unique. Have you already registered this user?',
        life: 5000,
      })
    }
  },
  async forgotPassword({ commit }, { email, $toast }) {
    try {
      const res = await ax.post('/auth/password/reset/', {
        email,
      })
      console.log(res)
      $toast.add({
        severity: 'success',
        group: 'tr',
        summary: 'Reset E-mail Sent',
        detail: 'Check your email for instructions on how to reset your password.',
        life: 5000,
      })
      router.push('/login')
    } catch (e) {
      $toast.add({
        severity: 'error',
        group: 'tr',
        summary: 'Reset Failed',
        detail: 'Please check that the email address is correct and try again',
        life: 5000,
      })
    }
  },
  async getCurrentUser({ commit }) {
    const res = await ax.get('/auth/user')
    commit('updateCurrentUser', res.data)
  },
  async updateDistrictInformation({ commit }, payload) {
    // Update district information
    await ax.patch(`/users/districts/${payload.id}/`, payload)

    // Get updated user information
    const res = await ax.get('/auth/user')
    commit('updateCurrentUser', res.data)
  },
  async getUsers({ commit }, { page = 1, pageSize = 20, sortField = null }) {
    const url = sortField
      ? `/users/?page=${page}&page_size=${pageSize}&ordering=${sortField}`
      : `/users/?page=${page}&page_size=${pageSize}`
    const res = await ax.get(url)
    return res.data
  },
  async clearUsers({ commit }) {
    commit('clearUsers')
  },
  async searchUsers({ commit }, term) {
    const res = await ax.get(`/users?search=${term}`)
    return res.data
  },
  checkAuthentication({ commit, dispatch }) {
    commit('setCheckingAuth', true)
    const hasToken = !!Auth.accessToken

    if (hasToken) {
      commit('logUserIn', {
        user: Auth.currentUser,
        authenticated: true,
      })
      commit('setCheckingAuth', false)
    } else {
      dispatch('logout')
    }
  },
  logout: ({ commit }) => {
    commit('clearAllUserData')
    Auth.logout()
    router.push('/login')
  },
  async activateUsers({ commit, dispatch }, { userIds, activate }) {
    await ax.post('/users/approvals/', {
      activate,
      userIds,
    })
  },
  async deactivateUsers({ commit }, payload) {
    await ax.post('/users/approvals/', {
      activate: false,
      userIds: payload,
    })
  },
  async saveSettings({ commit, state }, payload) {
    await ax.put(`/users/${state.currentUser.id}/`, payload)
    commit('updateCurrentUser', { ...state.currentUser, payload })
  },
}
