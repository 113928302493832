import ax from '@/store/utils/axiosConfig'
// import router from '../../router'

export default {
  namespaced: true,
  state: {
    districtReports: {},
    assessmentReport: {},
  },
  mutations: {
    updateDistrictReports(state, payload) {
      state.districtReports = payload
    },
    updateAssessmentReport(state, payload) {
      state.assessmentReport = payload
    },
    clearAllReportData(state) {
      // this is used to reset data for users who log out or whose authentication expires
      state.districtReport = {}
    },
  },
  actions: {
    async getDistrictReports({ commit }, districtId) {
      // Cumulative report for district with comparisons of other districts
      const res = await ax.get(`/district-reports/${districtId}`)
      commit('updateDistrictReports', res.data)
    },
    async getAssessmentReport({ commit, rootState }, districtAssessmentId) {
      const res = await ax.get(`/assessment-reports/${districtAssessmentId}/`)
      commit('updateAssessmentReport', res.data)
    },
    async getAssessmentReportDownload({ commit, rootState }, { type, id }) {
      const res = await ax.get(`/assessment-reports/${id}/download/?type=${type}`)

      // TODO: cleanup:
      if (type === 'pdf') {
        const url = `data:application/pdf;base64,${res.data}`
        // const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'assessment-detailed-report.pdf')
        document.body.appendChild(link)
        link.click()
      } else {
        const url = `data:application/pdf;base64,${res.data}`
        // const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'assessment-summary-report.pdf')
        document.body.appendChild(link)
        link.click()
      }
      // commit('updateAssessmentReport', res.data)
    },
    async createDistrictAssessmentReport({ commit }, districtAssessmentId) {
      const res = await ax.get(`/assessment-reports/${districtAssessmentId}/create/`)
      return res.data
    },
    async uploadDistrictAssessmentReportFiles({ commit }, payload) {
      const districtAssessmentId = payload.districtAssessmentId
      const reports = payload.reports
      await ax.post(`/assessments/district-assessments/${districtAssessmentId}/reports/`, reports)
    },
    clearAllReportData({ commit }) {
      commit('clearAllReportData')
    },
  },
}
