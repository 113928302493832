<template>
  <div
    v-for="(answer, j) in filteredAnswers"
    :key="`id-${answer.id}` || `index-${j}`"
    class="p-grid"
  >
    <div
      v-if="!['Text', 'Numeric', 'File', 'Slider Rating'].includes(type?.name)"
      class="p-col-12"
    >
      <label for="name">{{ type?.name === 'Rollup' ? 'Sub-Question' : 'Answer' }} Text</label>
      <i
        v-if="
          (filteredAnswers?.length > 1 && type?.name !== 'Rollup') ||
          (type?.name === 'Rollup' && filteredAnswers?.length > 2)
        "
        @click="() => removeAnswer({ qIndex, aIndex: j })"
        class="pi pi-trash p-ml-3"
      ></i>
      <br />
      <InputText
        :value="question.answers[j].text"
        @input="(e) => updateAnswer({ qIndex, aIndex: j, field: 'text', value: e.target.value })"
        type="text"
        class="full-width-input"
      />
    </div>
    <div v-if="type?.name === 'File' && j === 0" class="p-col-12">
      <label for="type">
        FILE UPLOAD<br />
        <small class="block"
          ><em>
            The user will be required to upload a file as an answer to this question. There will
            also be a comment field displayed for the user to provide details.
          </em></small
        >
      </label>
    </div>
    <div
      v-if="
        !['Text', 'Numeric', 'Slider Rating', 'Rollup', 'Comparison', 'File'].includes(
          type?.name,
        ) ||
        (type.name === 'File' && j === 0)
      "
      class="p-col-6"
    >
      <!-- For multiple choice questions, each answer has a score/weight -->
      <label for="type">SCORE</label><br />
      <Dropdown
        :modelValue="getAnswerWeight(question.answers[j].weight)"
        @change="(e) => updateAnswer({ qIndex, aIndex: j, field: 'weight', value: e.value })"
        :options="weights"
        optionLabel="name"
        class="half-width-input"
      />
    </div>
    <div v-if="type?.name === 'Rollup'" class="p-col-12">
      <!-- For rollup questions, each input has a score/weight scale with defined thresholds -->
      <label for="type">
        SCORE SCALE<br />
        <small class="block"
          ><em>
            The scoring scale is configured as key:value pairs, where the key is the maximum value
            to receive that score, and the value is the score itself. For example, with a scoring
            scale of
            <pre>{"0": -1, "25": 0, "50": 1, "100": 2}</pre>
            a value of 0 would be scored -1, a value of 1-25 would be scored 0, a value of 26-50
            would be scored 1, and a value of 51-100 would be scored 2.
          </em></small
        >
      </label>
      <Textarea
        :modelValue="getRollupScoringWeights(j)"
        @change="
          (e) =>
            updateAnswer({
              qIndex,
              aIndex: j,
              field: 'scoring',
              value: { weights: e.target.value },
            })
        "
        class="full-width-input"
      />
    </div>
    <div
      v-if="
        !['Multiple Select', 'Rollup', 'Comparison', 'File'].includes(type?.name) ||
        (type?.name === 'File' && j === 0)
      "
      class="p-col-6"
    >
      <label for="type" style="margin-left: 20px">SEND TO</label><br />
      <Dropdown
        :modelValue="sendToValue({ qIndex, aIndex: j, sendTo: answer.send_to })"
        @change="(e) => updateAnswer({ qIndex, aIndex: j, field: 'send_to', value: e.value })"
        :options="remappedQuestionsList"
        optionLabel="label"
        optionGroupLabel="label"
        optionGroupChildren="children"
        optionValue="key"
        class="half-width-input"
        style="margin-left: 20px"
      />
    </div>

    <div class="p-col-12" v-if="type?.name !== 'File' || j === 0">
      <label for="name">RESPONSE</label><br />
      <QuillEditor
        :value="answer.selected_response"
        :options="editorOptions"
        @change="onEditorChange($event, { qIndex, aIndex: j, field: 'selected_response' })"
      />

      <div v-if="['Multiple Select', 'Multiple Choice', 'Dichotomous'].includes(type?.name)">
        <label for="name"
          >NOT SELECTED RESPONSE
          <small class="block"
            ><em>This will be displayed if the user does not select this answer.</em></small
          >
        </label>
        <br />
        <QuillEditor
          :value="answer.not_selected_response"
          :options="editorOptions"
          @change="onEditorChange($event, { qIndex, aIndex: j, field: 'not_selected_response' })"
        />
      </div>
      <Divider />
    </div>
  </div>

  <div
    v-if="['Multiple Choice', 'Multiple Select', 'Rollup'].includes(type?.name)"
    class="p-d-flex"
  >
    <Button
      @click="() => addAnswer({ qIndex })"
      :label="`Add ${type?.name === 'Rollup' ? 'Sub-Question' : 'Answer'}`"
      icon="pi pi-plus-circle"
      class="p-button-text"
    />
  </div>
  <!-- <template v-if="type?.name === 'Numeric'">
      <div> -->
  <!-- TODO: add validation for min and max numbers -->
  <!-- <label for="name">MAX NUMBER</label><br>
        <InputText
          :value="question.answers[0].text"
          @input="(e) => updateAnswer({ qIndex, aIndex: 0, field: 'weight', value: e.target.value })"
          type="number"
          class="full-width-input"
        />
      </div>
    </template> -->
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import Button from 'primevue/button'
import Divider from 'primevue/divider'
import Dropdown from 'primevue/dropdown'
import InputText from 'primevue/inputtext'
import Textarea from 'primevue/textarea'
import { quillEditor as QuillEditor } from 'vue3-quill'
import _ from 'lodash'

const weights = [-4, -3, -2, -1, 0, 1, 2, 3, 4]

export default {
  components: {
    Button,
    Divider,
    Dropdown,
    InputText,
    QuillEditor,
    Textarea,
  },
  props: {
    qIndex: {
      type: Number,
    },
    question: {
      type: Object,
    },
    type: {
      type: Object,
    },
  },
  data() {
    return {
      weights: weights.map((weight) => ({ name: weight, code: weight })),
      editorOptions: {
        placeholder: 'Enter Response Text',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'code-block'],
            [{ header: 1 }, { header: 2 }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link', 'image'],
          ],
        },
      },
    }
  },
  computed: {
    ...mapState('assessments', ['currentSubSectionInfo', 'nestedQuestionsList']),
    remappedQuestionsList() {
      // Create array of subsections for display
      const questions = this.nestedQuestionsList.reduce((acc, val) => {
        return acc.concat(val.children)
      }, [])
      return questions
    },
    filteredAnswers() {
      return this.question.answers.filter((answer) => {
        return !answer.deleted
      })
    },
  },
  watch: {
    question(newQuestion, prevQuestion) {
      this.$forceUpdate()
    },
  },
  methods: {
    ...mapMutations('assessments', ['addAnswer', 'removeAnswer', 'updateAnswer']),
    ...mapActions('assessments', ['getNestedQuestionsList']),
    getAnswerWeight(weight) {
      if (typeof weight === 'number') {
        return { name: weight, code: weight }
      }
      return weight
    },
    getRollupScoringWeights(answerIndex) {
      const scoring = this.question.answers[answerIndex].scoring
      const weights = scoring?.weights

      return typeof weights === 'string' ? weights : JSON.stringify(weights || {})
    },
    onEditorChange({ quill, html, text }, { qIndex, aIndex, field }) {
      this.updateAnswer({ qIndex, aIndex, field, value: html })
    },
    sendToValue({ qIndex, aIndex, sendTo }) {
      // If `sendTo` is an object, we need to pull either the `id` or `key` off for
      // matching to the dropdown values
      if (sendTo && _.isObject(sendTo)) {
        if (sendTo.key) return sendTo.key
        return sendTo.id
      }

      // Otherwise, we need to find the matching question and return the full object
      const questions = _.flatten(_.map(this.remappedQuestionsList, (s) => s.children))
      const match = _.find(questions, (q) => q.key === sendTo)

      if (match) {
        this.updateAnswer({ qIndex, aIndex, field: 'send_to', value: match })
        return match
      }

      return null
    },
  },
  mounted() {
    const assessmentId = this.$route.params.id
    // when question changes, check for question_answer and populate
    this.getNestedQuestionsList(assessmentId)
  },
}
</script>

<style scoped>
.half-width-input {
  width: 150px;
}
</style>
