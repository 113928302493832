<template>
  <div class="p-grid registration-page p-m-5">
    <div class="p-col-12 header p-d-flex p-ai-center">
      <img src="../assets/icons/apple.png" class="p-mr-3" />
      <h1>Manage Users</h1>
    </div>
    <div class="p-col-12 p-d-flex p-jc-between p-ai-center">
      <div>
        <Button
          label="Add User"
          class="blue-orange-button p-mr-3"
          @click="$router.push('/users/registration')"
        />
        <Button
          @click="() => updateUserApprovals(true)"
          label="ACTIVATE"
          icon="pi pi-check"
          class="p-button-text p-mr-3"
        />
        <Button
          @click="() => updateUserApprovals(false)"
          label="DEACTIVATE"
          icon="pi pi-trash"
          class="p-button-text"
        />
      </div>
      <div class="p-d-flex p-ai-center">
        <p class="inline-label p-mr-4">Search:</p>
        <InputText @keydown="search" type="text" v-model="searchValue" />
      </div>
    </div>
    <div class="p-col">
      <DataTable
        :value="users.data"
        :loading="loading"
        :rowHover="true"
        @row-select="onRowSelect"
        responsiveLayout="scroll"
        selectionMode="multiple"
        dataKey="id"
        :paginator="true"
        :rows="20"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :lazy="true"
        ref="dt"
        :totalRecords="totalRecords"
        @page="onPage($event)"
        @sort="onSort($event)"
        filterDisplay="row"
      >
        <template #loading> Loading user data. Please wait. </template>
        <template #empty> No users found. </template>
        <Column field="checked" header="" headerStyle="width: 3rem">
          <template #body="{ data }">
            <Checkbox v-model="data.checked" :binary="true" />
          </template>
        </Column>
        <Column field="user_profile.district.name" header="District"></Column>
        <Column field="email" header="Email Address" :sortable="true"> </Column>
        <Column field="full_name" header="Name" :sortable="true"></Column>
        <Column field="role" header="Role"></Column>
        <Column field="user_profile.zip" header="Zip Code"></Column>
        <Column field="created_at" header="Added">
          <template #body="{ data }">
            {{ data.created_at?.split('T')[0] }}
          </template>
        </Column>
        <Column field="user_profile.title" header="Title"></Column>
        <Column field="approved" header="Status">
          <template #body="{ data }">
            {{ data.approved ? 'Approved' : 'Not Approved' }}
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Checkbox from 'primevue/checkbox'

export default {
  components: {
    Button,
    InputText,
    DataTable,
    Column,
    Checkbox,
  },
  data() {
    return {
      searchValue: '',
      loading: false,
      checkedRows: [],
      users: [],
      totalRecords: 0,
    }
  },
  methods: {
    ...mapActions('users', ['activateUsers', 'searchUsers', 'getUsers', 'clearUsers']),
    getSortDirection(field, direction) {
      const sortField = field === 'full_name' ? 'last_name' : field
      return direction > 0 ? sortField : `-${sortField}`
    },
    onRowSelect(e) {
      const index = this.users.findIndex((user) => {
        return user.id === e.data.id
      })
      this.users[index].checked = !e.data.checked
    },
    async onPage(e) {
      this.loading = true
      const sort = this.getSortDirection(e.sortField, e.sortOrder)
      this.users = await this.getUsers({ page: e.page + 1, pageSize: 20, sortField: sort })
      this.loading = false
    },
    async onSort(e) {
      this.loading = true
      const sort = this.getSortDirection(e.sortField, e.sortOrder)
      this.users = await this.getUsers({ page: 1, pageSize: 20, sortField: sort })
      this.loading = false
    },
    async updateUserApprovals(activate) {
      const userIds = this.users.data.filter((user) => user.checked).map((user) => user.id)
      if (userIds.length) {
        this.loading = true
        await this.activateUsers({ userIds, activate })
        this.users = await this.getUsers({ page: 1, pageSize: 20 })
        this.loading = false
      }
    },
    async search(e) {
      this.loading = true
      this.users = await this.searchUsers(e.target.value)
      this.totalRecords = this.users.meta.total
      this.loading = false
    },
  },
  async mounted() {
    this.loading = true
    this.users = await this.getUsers({ page: 1, page_size: 20 })
    this.totalRecords = this.users.meta.total
    this.loading = false
  },
  unmounted() {
    this.clearUsers()
  },
}
</script>
