<template>
  <div class="p-col-12 header p-d-flex p-ai-center">
    <img src="../../assets/icons/notes.png" class="p-mr-3" />
    <h1>Assessment Creator</h1>
  </div>
  <div class="p-xl-3 desktop-only">
    <AssessmentSidebar :updateCurrentStep="updateCurrentStep" :menuItems="menuItems" />
  </div>
  <div class="p-xl-9 p-xs-12 p-mb-6">
    <p class="progress-bar-label">
      {{ labels[currentStep - 1] }}
    </p>
    <ProgressBar :value="progress" :showValue="false" class="p-mb-5" />
    <SetupAssessment
      v-if="currentStep === 1"
      :types="types"
      :setupDataFormErrors="setupDataFormErrors"
    />
    <AddSections v-else-if="currentStep === 2 && $route.params?.id" />
  </div>
  <div class="p-col-12 p-d-flex p-jc-end p-ai-center">
    <!-- TODO: cancel button needs to clear assessment from Vuex -->
    <Button
      v-if="currentStep === 1"
      label="Cancel"
      class="text-button-underline p-mr-3"
      @click="() => clearAssessment(true)"
    />
    <Button
      v-else-if="!showQuestions"
      label="Back"
      class="text-button-underline p-mr-3"
      @click="backStep"
    />
    <Button
      v-if="showQuestions"
      label="Save Questions"
      class="blue-orange-button p-mr-3 p-ml-6"
      style="width: 220px"
      @click="saveQuestions"
    />
    <Button
      v-else-if="currentStep < 2"
      label="Next"
      class="blue-orange-button p-mr-3 p-ml-6"
      @click="nextStep"
    />
    <Button
      v-else
      label="Submit"
      class="blue-orange-button p-mr-3 p-ml-6"
      @click="submitAssessment"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import Button from 'primevue/button'
import ProgressBar from 'primevue/progressbar'

import AssessmentSidebar from '@/components/assessment-wizard/AssessmentSidebar.vue'
import SetupAssessment from '@/components/assessment-wizard/SetupAssessment.vue'
import AddSections from '@/components/assessment-wizard/AddSections.vue'

export default {
  setup() {
    return { v$: useVuelidate() }
  },
  components: {
    ProgressBar,
    Button,
    AssessmentSidebar,
    SetupAssessment,
    AddSections,
  },
  data() {
    return {
      labels: ['Setup Assessment', 'Add Sections'],
      setupDataFormErrors: [],
      districts: [],
    }
  },
  validations() {
    return {
      name: { required },
      type: { required },
    }
  },
  computed: {
    ...mapState('assessments', ['currentStep', 'types', 'currentAssessment', 'showQuestions']),
    progress() {
      return this.currentStep * 33
    },
    menuItems() {
      const items = [
        {
          label: 'Assessment Info',
          icon: 'pi pi-book',
          command: () => {
            this.updateCurrentStep(1)
          },
        },
        {
          label: 'Sections',
          items: [],
        },
      ]

      if (this.currentAssessment.sections.length > 0) {
        this.currentAssessment.sections.forEach((section, i) => {
          const sectionWithSubSections = [
            {
              label: section.name || `New Section ${i + 1}`,
              icon: 'pi pi-folder',
              command: () => {},
            },
          ]

          if (section?.subsections) {
            section.subsections.forEach((sub) => {
              sectionWithSubSections.push({
                label: sub.name || `New SubSection ${i + 1}`,
                icon: 'pi pi-angle-right',
                command: () => {},
              })
            })
          }
          items[1].items.push(...sectionWithSubSections)

          // TODO: add questions to the sidebar
          //       {
          //         label: 'Question',
          //         icon: 'pi pi-minus',
          //         command: () => {
          //         }
        })
      }
      return items
    },
  },
  methods: {
    ...mapMutations('assessments', [
      'clearAssessment',
      'updateCurrentStep',
      'toggleShowQuestions',
    ]),
    ...mapActions(['getDistricts']),
    ...mapActions('assessments', [
      'createAssessment',
      'updateAssessment',
      'saveAssessmentDetails',
      'getAssessmentById',
      'saveSubSectionQuestions',
    ]),
    nextStep(isValid) {
      if (this.currentStep === 1) {
        const errors = []
        if (!this.currentAssessment?.setupData?.name) errors.push('name')
        if (!this.currentAssessment?.setupData?.type) errors.push('type')
        if (this.currentAssessment?.setupData?.enablePeerDistrict) {
          if (!this.currentAssessment?.setupData?.offerThreshold) {
            errors.push('offer-threshold')
          }
          if (!this.currentAssessment?.setupData?.receiveThreshold) {
            errors.push('receive-threshold')
          }

          if (
            this.currentAssessment?.setupData?.offerThreshold <=
            this.currentAssessment?.setupData?.receiveThreshold
          ) {
            errors.push('offer-threshold')
            errors.push('receive-threshold')
            this.$toast.add({
              severity: 'error',
              group: 'tr',
              summary: 'Invalid',
              detail:
                'The offer threshold must be greater than the receive threshold for peer district matching.',
              life: 5000,
            })
          }
        }

        if (errors.length) {
          this.setupDataFormErrors = errors
          this.$toast.add({
            severity: 'error',
            group: 'tr',
            summary: 'Invalid',
            detail: 'Please fill out all information before moving on.',
            life: 5000,
          })
          return false
        } else {
          // save basic assessment info to database
          const payload = {
            ...this.currentAssessment.setupData,
            type: this.currentAssessment.setupData?.type?.id,
            districts:
              this.currentAssessment?.setupData &&
              this.currentAssessment.setupData.districts.map((district) => district.id),
          }
          this.createAssessment({ payload, $toast: this.$toast })
        }
      }
      this.updateCurrentStep(this.currentStep + 1)
    },
    submitAssessment() {
      // this saves all sections, subsections, and questions/answers
      const setupData = this.currentAssessment.setupData
      if (!setupData?.name || !setupData?.type) {
        // If basic assessment details are not defined, send them back to step 1.
        // Ideally, they should never end up at this position unless something has gone wrong
        // by allowing them to make it past step one without filling out and saving that info.
        this.updateCurrentStep(1)
        this.$toast.add({
          severity: 'error',
          group: 'tr',
          summary: 'Missing Assessment Info',
          detail: 'Please fill out all information before moving on.',
          life: 5000,
        })
        return false
      }

      // TODO: custom validation final step for form
      this.saveAssessmentDetails(this.$toast)
    },
    saveQuestions() {
      // TODO: need to check that questions are filled out properly and each
      // question shold have at least 1 answer
      this.saveSubSectionQuestions()
    },
    backStep() {
      if (this.currentStep > 1) {
        this.updateCurrentStep(this.currentStep - 1)
      }
    },
  },
  async mounted() {
    this.updateCurrentStep(1)
    this.toggleShowQuestions(false)
    this.districts = await this.getDistricts({})
    if (this.$route.params.id) {
      this.getAssessmentById({
        assessmentId: this.$route.params.id,
        $toast: this.$toast,
        districtList: this.districts,
      })
    }
  },
}
</script>
