<template>
  <div class="p-col">
    <div class="p-d-flex p-ai-center">
      <Button
        @click="filter('all')"
        label="All Assessments"
        class="text-button-underline p-mr-4"
      />
      <Button
        @click="filter('hybrid')"
        label="Hybrid On-Site Assessments"
        class="text-button-underline p-mr-4"
      />
      <Button
        @click="filter('inprogress')"
        label="In Progress"
        class="text-button-underline p-mr-4"
      />
      <Button
        @click="filter('completed')"
        label="Completed"
        class="text-button-underline p-mr-4"
      />
    </div>
    <WideAssessment
      v-for="assessment in assessments.data"
      :key="assessment.title"
      :assessment="assessment"
      :filterType="filterType"
      :completedAt="assessment.completed_at"
      :toggleDownloadDialog="toggleDownloadDialog"
    />
    <Paginator :rows="10" :totalRecords="totalRecords" @page="onPage($event)"></Paginator>
  </div>
  <div v-if="filterType === 'all'" class="p-col-12 p-d-flex p-jc-end">
    <Button
      label="Add Assessment"
      class="blue-orange-button p-mr-3"
      @click="$router.push('/assessment-creator')"
    />
  </div>
  <Dialog v-model:visible="displayDownloadDialog" class="p-flex-row">
    <h2>Select report to download:</h2>
    <div class="p-d-flex">
      <div
        @click="() => downloadReport('Detailed')"
        class="download-box p-mr-4 p-d-flex p-ai-center p-jc-center"
      >
        <strong>Detailed</strong>
      </div>
      <div
        @click="() => downloadReport('Summary')"
        class="download-box p-mr-4 p-d-flex p-ai-center p-jc-center"
      >
        <strong>Summary</strong>
      </div>
      <div
        :v-if="currentUser.role === 'Superuser'"
        @click="() => downloadReport('Admin Detailed')"
        class="download-box word-box p-d-flex p-ai-center p-jc-center"
      >
        <strong>Admin Detailed</strong>
      </div>
    </div>
  </Dialog>
  <Dialog v-model:visible="displayDistrictInfoDialog" class="p-flex-row" style="width: 600px">
    <h2>Additional District Information Needed:</h2>
    <div class="questions p-d-flex">
      <div class="question p-d-flex">
        <span>District Type:</span>
        <div class="options">
          <template v-for="option in districtTypeOptions" :key="option.value">
            <div class="option">
              <RadioButton
                :name="`district-type-${option.value}`"
                :value="option.value"
                :modelValue="this.districtType"
                @click="() => updateDistrictType(option.value)"
              />
              <label :for="`label`" @click="() => updateDistrictType(option.value)">{{
                option.label
              }}</label>
            </div>
          </template>
        </div>
      </div>

      <div class="question p-d-flex">
        <span>Student Enrollment:</span>
        <div class="options">
          <template v-for="option in districtEnrollmentBrackets" :key="option.value">
            <div class="option">
              <RadioButton
                :name="`district-type-${option.value}`"
                :value="option.value"
                :modelValue="this.districtEnrollmentBracket"
                @click="() => updateDistrictEnrollmentBracket(option.value)"
              />
              <label :for="`label`" @click="() => updateDistrictEnrollmentBracket(option.value)">{{
                option.label
              }}</label>
            </div>
          </template>
        </div>
      </div>
    </div>

    <div class="question p-d-flex" style="margin-top: 20px">
      <span>Would you like to opt-in to the Peer District Network?</span>
      <p>
        <em>
          When you opt-in to the Peer District Network, Assessments that are configured to match
          districts may match your district to another based on the results of the assessment.
        </em>
      </p>
      <div class="option" style="margin-top: -10px; margin-bottom: 20px">
        <Checkbox
          :modelValue="this.peerNetworkOptIn"
          :binary="true"
          @change="() => updatePeerNetworkOptIn(!this.peerNetworkOptIn)"
          name="opt-in"
        />
        <label :for="'opt-in'" @click="() => updatePeerNetworkOptIn(!this.peerNetworkOptIn)"
          >Opt-In</label
        >
      </div>
    </div>

    <div style="display: flex; justify-content: end">
      <Button
        :disabled="checkDistrictInformation()"
        label="Save"
        class="blue-orange-button"
        @click="submitDistrictInformation()"
      />
    </div>
  </Dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Button from 'primevue/button'
import Checkbox from 'primevue/checkbox'
import RadioButton from 'primevue/radiobutton'
import Dialog from 'primevue/dialog'
import Paginator from 'primevue/paginator'

import WideAssessment from '@/components/cards/WideAssessment.vue'

// Constants
import { districtEnrollmentBrackets, districtTypeOptions } from '@/data/constants'

// Utils
import { printReportPDF, reportPDF, reportName } from '../../utils/pdfHelper'

export default {
  components: {
    Button,
    Checkbox,
    Dialog,
    Paginator,
    RadioButton,
    WideAssessment,
  },
  data() {
    return {
      filterType: 'all',
      displayDownloadDialog: false,
      displayAssignHybridAssessmentDialog: false,
      selectedAssessmentReportId: null,
      selectedAssessmentReport: null,
      reportType: '',
      assessments: [],
      totalRecords: 0,
      displayDistrictInfoDialog: false,
      districtTypeOptions,
      districtEnrollmentBrackets,
      districtType: null,
      districtEnrollmentBracket: null,
      peerNetworkOptIn: false,
    }
  },
  props: {
    district: {},
  },
  computed: {
    ...mapState('reports', ['assessmentReport']),
    ...mapState('users', ['currentUser']),
  },
  methods: {
    ...mapActions('assessments', [
      'getAssessments',
      'getFilteredDistrictAssessments',
      'clearAllAssessmentData',
    ]),
    ...mapActions('reports', ['getAssessmentReport']),
    ...mapActions('users', ['updateDistrictInformation']),
    async filter(type) {
      this.filterType = type

      if (type === 'all' || type === 'hybrid') {
        this.assessments = await this.getAssessments({
          page: 1,
          pageSize: 10,
          sortField: '-created_at',
          district: this.district,
          type: type,
        })
      } else {
        this.assessments = await this.getFilteredDistrictAssessments({
          page: 1,
          pageSize: 10,
          sortField: '-updated_at',
          filterBy: type,
          district: this.district,
        })
      }

      this.totalRecords = this.assessments.meta.total
    },
    async onPage(e) {
      if (this.filterType === 'all') {
        this.assessments = await this.getAssessments({
          page: e.page + 1,
          pageSize: 10,
          sortField: '-created_at',
          district: this.district,
        })
      } else {
        this.assessments = await this.getFilteredDistrictAssessments({
          page: e.page + 1,
          pageSize: 10,
          sortField: '-updated_at',
          filterBy: this.filterType,
          district: this.district,
        })
      }
    },
    async toggleDownloadDialog(assessment) {
      this.selectedAssessmentReportId = assessment.id
      this.getAssessmentReport(assessment.id)
      this.displayDownloadDialog = !this.displayDownloadDialog
    },
    downloadReport(type) {
      const report = reportPDF(this.assessmentReport, type)
      const name = reportName(this.assessmentReport, type, this.currentUser)

      printReportPDF(report, name)
      this.displayDownloadDialog = false
      this.$toast.add({
        severity: 'success',
        group: 'tr',
        summary: 'Downloading PDF Report',
        detail: 'Please wait.',
        life: 10000,
      })
    },
    checkDistrictInformation() {
      return this.districtType === null || this.districtEnrollmentBracket === null
    },
    updateDistrictType(value) {
      this.districtType = value
    },
    updateDistrictEnrollmentBracket(value) {
      this.districtEnrollmentBracket = value
    },
    updatePeerNetworkOptIn(value) {
      this.peerNetworkOptIn = value
    },
    submitDistrictInformation() {
      this.updateDistrictInformation({
        id: this.currentUser.user_profile.district.id,
        type: this.districtType,
        student_enrollment_range: this.districtEnrollmentBracket,
        opt_in_peer_network: this.peerNetworkOptIn,
      })
      this.displayDistrictInfoDialog = false
    },
  },
  async mounted() {
    this.$watch(
      'district',
      async (district) => {
        this.assessments = await this.getAssessments({
          page: 1,
          pageSize: 10,
          sortField: '-created_at',
          filterType: this.filterType,
          district: district,
          type: this.filterType,
        })
        this.totalRecords = this.assessments.meta?.total
      },
      { immediate: true },
    )

    if (
      this.currentUser.user_profile.district.type === null ||
      this.currentUser.user_profile.district.student_enrollment_range === null
    ) {
      this.districtEnrollmentBracket =
        this.currentUser.user_profile.district.student_enrollment_range
      this.districtType = this.currentUser.user_profile.district.type
      this.peerNetworkOptIn = this.currentUser.user_profile.district.opt_in_peer_network
      this.displayDistrictInfoDialog = true
    }
  },
  unmounted() {
    this.clearAllAssessmentData()
  },
}
</script>

<style lang="sass" scoped>
.blue-orange-button
  width: 231.71px
.download-box
  height: 45px
  width: 150px
  background: #326195
  border: none
  border-bottom: 7px solid #FF681D
  border-radius: 0
  color: #FFF
  &:hover
    cursor: pointer
.word-box
  background: #326195
  border: none
  border-bottom: 7px solid #FF681D
  border-radius: 0
  color: #FFF

.questions
  display: flex
  gap: 50px

.question
  display: flex
  flex-direction: column

  span
    font-weight: bold

  p
    margin-top: -5px

.options
  display: flex
  flex-direction: column

.option
  display: flex
  flex-direction: row
  align-items: center
  gap: 5px
</style>
