import ax from '@/store/utils/axiosConfig'

import router from '../../router'
import { templateSection, templateSubSection } from './createMutations'

export default {
  async searchAssessments({ commit }, term) {
    const res = await ax.get(`/assessments/district-assessments?search=${term}`)
    return res.data
  },
  async getAssessments(
    { commit },
    { page = 1, pageSize = 10, sortField = null, district = '', type = '' },
  ) {
    const sort = sortField ? `&ordering=${sortField}` : ''
    const districtFilter = district && district !== '' ? `&district=${district?.id}` : ''
    const typeFilter = type && ['all', 'hybrid'].includes(type) ? `&type=${type}` : ''

    const res = await ax.get(
      `/assessments/?page=${page}&page_size=${pageSize}${sort}${districtFilter}${typeFilter}`,
    )
    return res.data
  },
  async getFilteredDistrictAssessments(
    { commit },
    { page = 1, pageSize = 10, sortField = null, filterBy = null, district = null },
  ) {
    const sort = sortField ? `&ordering=${sortField}` : ''
    const districtFilter = district && district !== '' ? `&district=${district?.id}` : ''

    let filter = ''
    switch (filterBy) {
      case 'completed':
        filter = '&submitted=True'
        break
      case 'inprogress':
        filter = '&submitted=False'
        break
      default:
        break
    }

    const res = await ax.get(
      `/assessments/district-assessments/?page=${page}&page_size=${pageSize}${sort}${filter}${districtFilter}`,
    )
    return res.data
  },
  async getAssessmentsByDistrict({ dispatch, commit }, district) {
    commit('updateSelectedDistrict', district)
    if (!district) {
      dispatch('getAssessments', 'all')
      return
    }
    const res = await ax.get(`/assessments/?district=${district.id}`)
    commit('updateAssessments', res.data)
  },
  async getAssessmentById({ commit, state, rootState }, { assessmentId, $toast, districtList }) {
    try {
      const { data } = await ax.get(`/assessments/${assessmentId}/`)

      // remap and update setup data
      const districts = districtList.filter((district) => {
        if (data.get_districts_names.includes(district.name)) {
          return district
        }
      })
      const setupData = {
        id: data.id,
        name: data.name,
        description: data.description,
        quickdescription: data.quickdescription,
        type: state.types.find((type) => type.name === data.get_type_display),
        districts,
        isDefault: data.is_default,
        isReportless: data.is_reportless_default,
        enablePeerDistrict: data.enable_peer_district,
        offerThreshold: data.offer_threshold,
        receiveThreshold: data.receive_threshold,
      }

      commit('updateSetupData', setupData)
    } catch (err) {
      console.error('Error fetching assessment data', err)

      $toast.add({
        severity: 'error',
        group: 'tr',
        summary: 'Error fetching assessment data',
        detail: 'Please try again.',
        life: 5000,
      })
    }
  },
  async getAssessmentSections({ commit }, { assessmentId }) {
    // this fetches already created sections for an assessment so they can be edited
    try {
      const { data } = await ax.get(`/assessments/${assessmentId}/sections/`)

      commit('setSections', data)
    } catch (err) {
      console.error('Error fetching assessment section data', err)
    }
  },
  async getSubSectionQuestions({ commit, state }, { sub }) {
    // gets all questions for a specific subsection; used for AddQuestions component
    try {
      const assessmentId = state.currentAssessment.setupData?.id
      const { data } = await ax.get(`/assessments/${assessmentId}/questions/?sub=${sub}`)

      // remap some data here
      const remappedData = data.map((question) => {
        return {
          ...question,
          type: state.questionTypes.find((type) => type.id === question.type),
          weight: { code: question.weight, name: question.weight },
          answers: question.choices,
        }
      })

      commit('setCurrentQuestions', remappedData)
    } catch (err) {
      console.error('Error fetching question data for subsection', err)
    }
  },
  async saveSubSectionQuestions({ commit, state, getters }) {
    try {
      const assessmentId = state.currentAssessment.setupData?.id
      const subId = getters.currentSubSection.id
      await ax.post(`/assessments/${assessmentId}/questions/?sub=${subId}`, state.currentQuestions)

      commit('setCurrentQuestions', [])
      commit('toggleShowQuestions', false)
    } catch (err) {
      console.error('Error saving question data for subsection', err)
    }
  },
  async saveSubSection(
    { commit, state },
    { empty, sectionId, sectionIndex, subIndex, field, value },
  ) {
    try {
      const assessmentId = state.currentAssessment.setupData?.id

      let payload = templateSubSection
      if (!empty) {
        payload = {
          ...state.currentAssessment.sections[sectionIndex].subsections[subIndex],
          order: subIndex + 1,
        }
        payload[field] = value
        delete payload.questions
      }
      const { data } = await ax.post(
        `/assessments/${assessmentId}/subsections/?section=${sectionId}`,
        payload,
      )

      commit('updateSubSection', { sectionIndex, data })
    } catch (err) {
      console.error('Error saving subsection data', err)
    }
  },
  async deleteSubSection({ commit, state }, { sectionId, sectionIndex, subId, subIndex, $toast }) {
    try {
      const assessmentId = state.currentAssessment.setupData?.id
      await ax.delete(`/assessments/${assessmentId}/subsections/${subId}/`)

      commit('removeSubSection', { sectionIndex, subIndex })
    } catch (err) {
      console.error('Error saving subsection data', err)
      $toast.add({
        severity: 'error',
        group: 'tr',
        summary: 'Error Deleting Setion',
        detail: 'Cannot delete a section if the assessment is in use.',
        life: 5000,
      })
    }
  },
  async saveSection({ commit, state }, { empty, sectionIndex, field, value, $toast }) {
    try {
      const assessmentId = state.currentAssessment.setupData?.id

      // if the section exists, grab it
      // otherwise, pass in an empty section to save
      let payload = templateSection
      if (!empty) {
        payload = {
          ...state.currentAssessment.sections[sectionIndex],
          order: sectionIndex + 1,
        }
        payload[field] = value
        delete payload.subsections
      }
      const { data } = await ax.post(`/assessments/${assessmentId}/sections/`, payload)

      commit('updateSection', data)
    } catch (err) {
      console.error('Error saving section data', err)

      // this may be valuable feedback for other section saving errors, but potential error messages
      // should be vetted prior to exposing them directly to users outside of this intended case
      if ($toast && err.response.data.startsWith('Peer district')) {
        $toast.add({
          severity: 'error',
          group: 'tr',
          summary: 'Error Saving Section',
          detail: err.response.data,
          life: 5000,
        })
      }
    }
  },
  async displaySubSectionPreview({ commit, state }, { subId }) {
    // gets all questions for a specific subsection; used for AddQuestions component
    try {
      const assessmentId = state.currentAssessment.setupData?.id
      const { data } = await ax.get(`/assessments/${assessmentId}/questions/?sub=${subId}`)

      const remappedData = data.map((question) => {
        return {
          question,
          type: state.questionTypes.find((type) =>
            type.id === question.type ? type.name : false,
          ),
          weight: { code: question.weight, name: question.weight },
          answers: question.choices,
        }
      })

      commit('setPreviewQuestions', remappedData)
      commit('setAssessmentDetails', state.currentAssessment.setupData)
      router.push(`/assessment-creator/${assessmentId}/subsection/${subId}/preview`)
    } catch (err) {
      console.error('Error fetching question data for subsection', err)
    }
  },
  async deleteSection({ commit, state }, { sectionId, sectionIndex, $toast }) {
    try {
      const assessmentId = state.currentAssessment.setupData?.id
      await ax.delete(`/assessments/${assessmentId}/sections/${sectionId}/`)

      commit('removeSection', sectionIndex)
    } catch (err) {
      console.error('Error deleting section', err)
      $toast.add({
        severity: 'error',
        group: 'tr',
        summary: 'Error Deleting Setion',
        detail: 'Cannot delete a section if the assessment is in use.',
        life: 5000,
      })
    }
  },
  async createAssessment({ commit }, { payload, $toast }) {
    try {
      const res = await ax.post('/assessments/', {
        ...payload,
      })
      commit('updateSetupData', res.data)
      router.replace(`/assessment-creator/${res.data.id}/edit`)
    } catch (err) {
      console.error('Invalid form data', err)

      $toast.add({
        severity: 'error',
        group: 'tr',
        summary: 'Form Unable to Save',
        detail: 'Please try again.',
        life: 5000,
      })
    }
  },
  async saveAssessmentDetails({ commit, state }, $toast) {
    // this saves the newly created assessment with all questions and answers
    // we are making a PUT request here to use the update function with DRF router and ModelViewSet
    // state.currentAssessment.setupData.type.id
    await ax.put(`/assessments/${state.currentAssessment.setupData?.id}/`, {
      setupData: {
        ...state.currentAssessment.setupData,
        type: state.currentAssessment.setupData.type?.id,
      },
      sections: state.currentAssessment.sections,
    })
    $toast.add({
      severity: 'success',
      group: 'tr',
      summary: 'Assessment Created Successfully',
      detail: 'Assessment is now ready to take.',
      life: 5000,
    })

    commit('clearAssessment')
    router.push('/assessments')
  },
  async getQuestionTypes({ commit }) {
    const res = await ax.get('/assessments/question-types')
    commit('updateQuestionTypes', res.data)
  },
  async getAssessmentQuestion(
    { commit },
    { assessmentId, districtAssessmentId, sendTo, queryParams: { sec, sub, q }, $toast },
  ) {
    // Gets one question at a time for an assessment based off of order query params

    const url = `/assessments/${assessmentId}/question-answer/?sec=${sec}&sub=${sub}&q=${q}&da=${districtAssessmentId}&sendto=${
      sendTo || ''
    }`
    return new Promise((resolve, reject) => {
      ax.get(url)
        .then((res) => {
          if (res.data.revisit_required) {
            $toast.add({
              severity: 'warn',
              group: 'tr',
              summary: 'Question Required!',
              detail: 'Please answer this required question.',
              life: 5000,
            })
            commit('updateAssessmentQuestion', res.data)
          } else if (res.data.completed) {
            const payload = {
              ...res.data,
              completed: true,
              queryParams: {
                sec: 1,
                sub: 1,
                q: 1,
              },
            }
            commit('updateAssessmentQuestion', payload)
            $toast.add({
              severity: 'success',
              group: 'tr',
              summary: 'Assessment Completed!',
              detail: 'Click the "Submit" button to calculate scores.',
              life: 5000,
            })
            // $toast.add(
            //   { severity: 'success', group: 'tr', summary: 'Assessment Completed!', detail: 'Scores will be available soon.', life: 5000 }
            // )
            // router.push('/assessments')
          } else {
            commit('updateAssessmentQuestion', res.data)
          }
          resolve(res)
        })
        .catch((err) => {
          console.log(err)
          reject(err)
        })
    })
  },
  async getQuestionById({ commit }, { districtAssessmentId, questionId }) {
    // this is used for getting a particular question when
    // navigating from sidebar
    const res = await ax.get(`/assessments/${districtAssessmentId}/questions-by-id/${questionId}/`)

    commit('updateAssessmentQuestion', res.data)
  },
  async getNestedQuestionsList({ commit }, assessmentId) {
    const res = await ax.get(`/assessments/${assessmentId}/nested-questions/`)

    commit('updateNestedQuestionsList', res.data)
  },
  async getAssignableUsers({ commit }, assessmentId) {
    const res = await ax.get(`/assessments/${assessmentId}/district-users/`)
    return res.data
  },
  async getSuperUsers({ commit }) {
    const res = await ax.get('/users/superusers')
    return res.data
  },
  async assignHybridAssessment({ commit }, { assessmentId, assigningUserId, onSiteUserId }) {
    const res = await ax.post(`/assessments/${assessmentId}/take/`, {
      taken_by: assigningUserId,
      on_site_by: onSiteUserId,
    })
    return res.data
  },
  async createDistrictAssessment({ commit, state }, assessment) {
    // Used to create district assessment for take assessment; difference from
    // `takeDistrictAssessment` is that this makes a POST request and uses
    // the assessment ID to create, return, and redirect to the district assessment
    const url = `/assessments/${assessment.id}/take/`
    return new Promise((resolve, reject) => {
      const createAndTakeDistrcictAssessment = async () => {
        ax.post(url)
          .then((res) => {
            commit('setAssessmentDetails', res.data)
            router.push(`/assessments/${res.data.district_assessment.id}/take`)
            resolve(res)
          })
          .catch((err) => {
            console.log('Could not take assessment:', err)
            reject(err)
          })
      }
      createAndTakeDistrcictAssessment()
    })
  },
  async takeDistrictAssessment({ commit, state }, districtAssessmentId) {
    // Used to get district assessment for take assessment; difference from
    // `createDistrictAssessment` is that this makes a GET request and uses
    // the district assessment ID directly
    const url = `/assessments/${districtAssessmentId}/take`

    return new Promise((resolve, reject) => {
      const getDistrictAssessment = async () => {
        ax.get(url)
          .then((res) => {
            commit('setAssessmentDetails', res.data)
            console.log(res.data)
            resolve(res)
          })
          .catch((err) => {
            console.log(err)
            reject(err)
          })
      }
      getDistrictAssessment()
    })
  },
  async saveQuestionAnswer(
    { commit, state },
    {
      districtAssessmentId,
      questionId,
      answer,
      keyMetrics,
      externalNotes,
      confidentialNotes,
      bestPractices,
    },
  ) {
    let headers = {}
    let payload = {}

    if (answer.file) {
      headers = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      const { file, ...answerData } = answer
      payload = new FormData()
      payload.append('question', questionId)
      payload.append('answer', JSON.stringify(answerData))
      payload.append('file', file)
    } else {
      payload = {
        question: questionId,
        answer,
        keyMetrics,
        externalNotes,
        confidentialNotes,
        bestPractices,
      }
    }

    const res = await ax.post(
      `/assessments/${districtAssessmentId}/question-answer/`,
      payload,
      headers,
    )
    console.log(res)
  },
  async getDistrictAssessments({ commit }, { page = 1, pageSize = 10, sortField = null }) {
    // this pulls entries from the DistrictAssessment table which contains completed assessments
    const sort = sortField ? `&ordering=${sortField}` : ''
    const res = await ax.get(
      `/assessments/district-assessments/?page=${page}&page_size=${pageSize}${sort}`,
    )
    return res.data
    // commit('updateDistrictAssessments', res.data)
  },
  async saveSelectedDistrict({ dispatch, commit }, { district, route }) {
    commit('updateSelectedDistrict', district)

    // check if user is on reports page or just assessments
    if (route === 'district-report') {
      // if there is an id on district that means that a district
      // has been selected from the field, as opposed to cleared
      if (district?.id) {
        dispatch('getDistrictReports', district.id)
      }
    }
  },
  clearAllAssessmentData({ commit }) {
    commit('clearAllAssessmentData')
  },
}
