<template>
  <div class="p-grid">
    <!-- TODO: might add this back in later -->
    <!-- <div class="p-col-4">
      <Card class="primary-card">
        <template #header>
          <h3>Assessment A</h3>
        </template>
        <template #subtitle>
          <div class="p-grid">
            <div class="p-col">Completion</div>
            <div class="p-col">Type</div>
          </div>
        </template>
        <template #content>
          <div class="p-grid">
            <div class="p-col">100%</div>
            <div class="p-col">C+</div>
          </div>
        </template>
        <template #footer>
          <Button label="Preview" class="text-button-underline" />
          <i class="pi pi-download"></i>
        </template>
      </Card>
    </div> -->
    <div v-if="!showQuestions" class="p-sm-10 p-md-12">
      <Card
        v-for="(section, i) in currentAssessment.sections"
        :key="section.id"
        class="section-card p-mb-4"
      >
        <template #content>
          <div class="main-section-content">
            <InputText
              type="text"
              :value="section.name"
              @input="
                (e) =>
                  debouncedSaveSection({ sectionIndex: i, field: 'name', value: e.target.value })
              "
              class="full-width-input p-mb-3"
              placeholder="Section Title"
            />
            <Textarea
              type="text"
              :value="section.description"
              @input="
                (e) =>
                  debouncedSaveSection({
                    sectionIndex: i,
                    field: 'description',
                    value: e.target.value,
                  })
              "
              placeholder="Description"
              class="full-width-textarea"
              rows="1"
              :autoResize="true"
            />
            <label>
              Score Threshold<br />
              <InputText
                type="number"
                min="0"
                max="100"
                :value="section.score_threshold"
                @input="
                  (e) =>
                    debouncedSaveSection({
                      sectionIndex: i,
                      field: 'score_threshold',
                      value: e.target.value,
                    })
                "
                class="percent-input-full-width"
                placeholder="Score Threshold"
              />
              <span class="percent-symbol">%</span>
            </label>
            <Textarea
              type="text"
              :value="section.score_response"
              @input="
                (e) =>
                  debouncedSaveSection({
                    sectionIndex: i,
                    field: 'score_response',
                    value: e.target.value,
                  })
              "
              placeholder="Unmet Score Threshold Response"
              class="full-width-textarea p-mb-3"
              rows="1"
              :autoResize="true"
            />

            <label
              v-if="currentAssessment.setupData.type.name === 'Hybrid'"
              :for="`section-${i}-restrict`"
              class="p-mt-0 p-mb-3"
            >
              <Checkbox
                v-model="section.on_site_restricted"
                :checked="section.on_site_restricted"
                :value="!section.on_site_restricted"
                :binary="true"
                :name="`section-${i}-restrict`"
                @change="(e) => saveSection({ sectionIndex: i })"
                class="mr-2"
              />
              Restrict to On-Site Assessor
            </label>

            <div v-if="currentAssessment.setupData.enablePeerDistrict">
              <label :for="`section-${i}-disable-peer-district`" class="p-mt-3 p-mb-0">
                <Checkbox
                  v-model="section.disable_peer_district"
                  :checked="section.disable_peer_district"
                  :value="!section.disable_peer_district"
                  :binary="true"
                  :name="`section-${i}-disable-peer-district`"
                  @change="(e) => saveSection({ sectionIndex: i, $toast })"
                  class="mr-2"
                />
                Disable Peer District Matching
              </label>
              <div v-if="!section.disable_peer_district">
                <label class="p-mr-3">
                  Offer Override ({{ currentAssessment.setupData.offerThreshold }}%)<br />
                  <InputText
                    type="number"
                    min="0"
                    max="100"
                    :value="section.offer_threshold_override"
                    @input="
                      (e) =>
                        debouncedSaveSection({
                          sectionIndex: i,
                          field: 'offer_threshold_override',
                          value: e.target.value,
                          $toast,
                        })
                    "
                    class="percent-input-half-width"
                    :placeholder="currentAssessment.setupData.offerThreshold"
                  />
                  <span class="percent-symbol">%</span>
                </label>
                <label>
                  Receive Override ({{ currentAssessment.setupData.receiveThreshold }}%)<br />
                  <InputText
                    type="number"
                    min="0"
                    max="100"
                    :value="section.receive_threshold_override"
                    @input="
                      (e) =>
                        debouncedSaveSection({
                          sectionIndex: i,
                          field: 'receive_threshold_override',
                          value: e.target.value,
                          $toast,
                        })
                    "
                    class="percent-input-half-width"
                    :placeholder="currentAssessment.setupData.receiveThreshold"
                  />
                  <span class="percent-symbol">%</span>
                </label>
              </div>
            </div>

            <div class="card-icons section">
              <i
                @click="() => deleteSection({ sectionId: section.id, sectionIndex: i, $toast })"
                class="pi pi-trash"
              ></i>
            </div>

            <div class="card-buttons">
              <Button
                label="Add Sub"
                icon="pi pi-plus-circle"
                class="p-button-text p-mr-4"
                @click="
                  () =>
                    debouncedSaveSubSection({
                      sectionId: section.id,
                      sectionIndex: i,
                      empty: true,
                    })
                "
              />
            </div>
          </div>

          <Card v-for="(sub, j) in section.subsections" :key="j" class="section-card p-mb-4">
            <template #content>
              <InputText
                type="text"
                :value="sub.name"
                @input="
                  (e) =>
                    debouncedSaveSubSection({
                      sectionId: section.id,
                      sectionIndex: i,
                      subIndex: j,
                      field: 'name',
                      value: e.target.value,
                    })
                "
                class="full-width-input p-mb-3"
                placeholder="Section Title"
              />
              <Textarea
                type="text"
                :value="sub.description"
                @input="
                  (e) =>
                    debouncedSaveSubSection({
                      sectionId: section.id,
                      sectionIndex: i,
                      subIndex: j,
                      field: 'description',
                      value: e.target.value,
                    })
                "
                class="full-width-textarea"
                placeholder="Description"
                rows="1"
                :autoResize="true"
              />

              <div class="card-icons">
                <!-- TODO: add confirm modal for deletion -->
                <i
                  @click="
                    () =>
                      deleteSubSection({
                        sectionId: section.id,
                        sectionIndex: i,
                        subId: sub.id,
                        subIndex: j,
                        $toast,
                      })
                  "
                  class="pi pi-trash"
                ></i>
                <i
                  @click="() => selectSubSection({ sectionIndex: i, subIndex: j })"
                  class="pi pi-pencil"
                ></i>
              </div>
              <div
                @click="
                  () =>
                    displaySubSectionPreview({
                      sectionId: section.id,
                      sectionIndex: i,
                      subId: sub.id,
                      subIndex: j,
                      $toast,
                      empty: true,
                    })
                "
                class="preview-button"
              >
                <div>Preview</div>
              </div>
            </template>
          </Card>
        </template>
      </Card>
      <div class="p-d-flex p-jc-end">
        <Button
          label="Add Section"
          icon="pi pi-plus-circle"
          class="p-button-text"
          @click="() => saveSection({ empty: true })"
        />
      </div>
    </div>
    <div v-else class="p-md-10 p-sm-12">
      <AddQuestions />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import Card from 'primevue/card'
import Checkbox from 'primevue/checkbox'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import Textarea from 'primevue/textarea'
import AddQuestions from './AddQuestions.vue'
import { debounce } from '@/utils/helpers'

export default {
  components: {
    Card,
    Checkbox,
    Button,
    InputText,
    AddQuestions,
    Textarea,
  },
  data() {
    return {
      debouncedSaveSection: null,
    }
  },
  computed: {
    ...mapState('assessments', ['currentAssessment', 'showQuestions']),
  },
  methods: {
    ...mapMutations('assessments', [
      'addSection',
      'removeSection',
      'updateSection',
      'addSubSection',
      'updateSubSection',
      'addQuestion',
      'removeQuestion',
      'selectSubSection',
      'setPreviewQuestions',
      'updateCurrentStep',
    ]),
    ...mapActions('assessments', [
      'getAssessmentSections',
      'saveSubSection',
      'saveSection',
      'deleteSection',
      'deleteSubSection',
      'displaySubSectionPreview',
    ]),
  },
  mounted() {
    this.debouncedSaveSection = debounce(this.saveSection)
    this.debouncedSaveSubSection = debounce(this.saveSubSection)

    // pull existing section, subsections if they exist
    this.getAssessmentSections({ assessmentId: this.$route.params?.id })
  },
  unmounted() {
    this.updateCurrentStep(1)
  },
}
</script>

<style lang="sass" scoped>
.text-button-underline
  padding-bottom: 0
  .p-button-label
    font-size: 16px
</style>
